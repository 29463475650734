import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import {
  cancelWhatsappSubscription,
  disableWhatsappConfig,
  enableWhatsappConfig,
  getWhatsappUsers,
  updateWhatsappConfig,
  updateWhatsappTierExpiry,
} from "../api";
import CreateConfigForm from "./CreateConfigForm";
import WhatsappOrdersTable from "./WhatsappOrdersTable";

const { Option } = Select;

const WhatsappManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // ['active', 'inactive']
  const [loading, setLoading] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalEnabled, setTotalEnabled] = useState(0);
  const [totalDisabled, setTotalDisabled] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [enablementModalVisible, setEnablementModalVisible] = useState(false);
  const [enablementState, setEnablementState] = useState(null); // ['enabled', 'disabled']
  const [showTierExpiryModal, setShowTierExpiryModal] = useState(false);
  const [selectedTierExpiryDate, setSelectedTierExpiryDate] = useState(null);
  const [autopayStatus, setAutopayStatus] = useState(null); // ['pending', 'halted', 'cancelled', 'paused', 'expired', 'completed', 'active', 'authenticated'
  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] =
    useState(false);

  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const formRef = useRef(null);

  const autopayStatuses = [
    "pending",
    "halted",
    "cancelled",
    "paused",
    "expired",
    "completed",
    "active",
    "authenticated",
  ];

  const getWhatsappUsersData = async () => {
    setLoading(true);
    try {
      const resp = await getWhatsappUsers(
        selectedStatus,
        subscriptionStatus,
        debouncedSearch,
        startDate?.toISOString(),
        endDate?.toISOString(),
        autopayStatus,
        page,
        10
      );
      if (resp.code === 0) {
        setUsers(resp.result?.data);
        setTotal(resp.result?.total);
        setTotalActive(resp.result?.totalActive);
        setTotalEnabled(resp.result?.totalEnabled);
        setTotalDisabled(resp.result?.totalDisabled);
        setTableKey(Date.now());
      } else {
        message.error("Error fetching whatsapp users");
      }
    } catch (err) {
      console.log(err);
      message.error("Error fetching whatsapp users");
    } finally {
      setLoading(false);
    }
  };

  const getWhatsappUsersCSV = async () => {
    try {
      const resp = await getWhatsappUsers(
        selectedStatus,
        subscriptionStatus,
        debouncedSearch,
        startDate?.toISOString(),
        endDate?.toISOString(),
        autopayStatus,
        undefined,
        undefined,
        true
      );
      fileDownload(resp, "whatsapp-users.csv");
    } catch (err) {
      console.log(err);
      message.error("Error fetching whatsapp users");
    }
  };

  const updateWhatsappConfigData = async () => {
    const hide = message.loading("Updating config...", 0);
    try {
      const values = await formRef.current.form.validateFields();
      const resp = await updateWhatsappConfig({
        config: values,
        userId: selectedUser._id,
      });
      if (resp.code === 0) {
        message.success("Updated successfully");
        await getWhatsappUsersData();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
      setShowConfigModal(false);
      setSelectedUser(null);
    }
  };

  const handleDisableWhatsappConfig = async (userId) => {
    try {
      const resp = await disableWhatsappConfig({ userId });
      if (resp.code === 0) {
        message.success("Disabled successfully");
        await getWhatsappUsersData();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setEnablementModalVisible(false);
      setEnablementState(null);
      setSelectedUser(null);
    }
  };

  const handleEnableWhatsappConfig = async (userId) => {
    try {
      const resp = await enableWhatsappConfig({ userId });
      if (resp.code === 0) {
        message.success("Enabled successfully");
        await getWhatsappUsersData();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setEnablementModalVisible(false);
      setEnablementState(null);
      setSelectedUser(null);
    }
  };

  const handleUpdateWhatsappExpiry = async (userId) => {
    try {
      const resp = await updateWhatsappTierExpiry({
        userId,
        expiryDate: selectedTierExpiryDate?.toISOString(),
      });
      if (resp.code === 0) {
        message.success("Updated successfully");
        await getWhatsappUsersData();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setShowTierExpiryModal(false);
      setSelectedUser(null);
      setSelectedTierExpiryDate(null);
    }
  };

  const handleCancelWhatsappSubscription = async (userId) => {
    const hide = message.loading("Cancelling subscription...");
    try {
      const resp = await cancelWhatsappSubscription({ userId });
      if (resp.code === 0) {
        message.success("Subscription cancelled successfully");
      } else {
        message.error(resp?.result || resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(
        err?.response?.data.result || err?.message || "Something went wrong"
      );
    } finally {
      hide();
      setCancelSubscriptionModalVisible(false);
      setSelectedUser(null);
    }
  };

  const getWhatsappsTable = () => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Registered Phone Number",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Whatsapp Status",
        dataIndex: "customWhatsappStatus",
        key: "customWhatsappStatus",
      },
      {
        title: "Subscription Status",
        dataIndex: "whatsappSubscriptionStatus",
        key: "whatsappSubscriptionStatus",
      },
      {
        title: "Subscription Expiry",
        dataIndex: "whatsappSubscriptionExpiry",
        key: "whatsappSubscriptionExpiry",
        render: (text) => {
          if (text) {
            return moment(text).format("DD/MM/YYYY");
          }
          return "-";
        },
      },
      {
        title: "Autopay Status",
        dataIndex: "autopayStatus",
        key: "autopayStatus",
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Space>
            {record?.customWhatsappStatus !== "disabled" ? (
              <>
                <Space direction="vertical">
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowConfigModal(true);
                      setSelectedUser(record);
                    }}
                    block
                  >
                    Set Config
                  </Button>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      setShowTierExpiryModal(true);
                      setSelectedUser(record);
                    }}
                    disabled={["active", "authenticated"].includes(
                      record?.autopayStatus
                    )}
                  >
                    Set Expiry
                  </Button>
                  <Button
                    type="primary"
                    block
                    disabled={
                      !["active", "authenticated"].includes(
                        record?.autopayStatus
                      )
                    }
                    onClick={() => {
                      setSelectedUser(record);
                      setCancelSubscriptionModalVisible(true);
                    }}
                  >
                    Cancel Subscription
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      setEnablementModalVisible(true);
                      setEnablementState("disabled");
                      setSelectedUser(record);
                    }}
                    block
                  >
                    Disable
                  </Button>
                </Space>
              </>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setEnablementModalVisible(true);
                  setEnablementState("enabled");
                  setSelectedUser(record);
                }}
              >
                Enable
              </Button>
            )}
          </Space>
        ),
      },
    ];
    return (
      <Table
        key={tableKey}
        dataSource={users}
        columns={columns}
        loading={loading}
        pagination={{
          current: page,
          pageSize: 10,
          total,
          onChange: (p) => setPage(p),
        }}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => (
            <WhatsappOrdersTable userId={record._id} />
          ),
        }}
      />
    );
  };

  useEffect(() => {
    getWhatsappUsersData();
  }, [
    selectedStatus,
    page,
    subscriptionStatus,
    debouncedSearch,
    startDate,
    endDate,
    autopayStatus,
  ]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={6}>
          <Card title="Total Users" bordered={false}>
            <Typography>{total}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Active Subscriptions" bordered={false}>
            <Typography>{totalActive}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Enabled Accounts" bordered={false}>
            <Typography>{totalEnabled}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Disabled Accounts" bordered={false}>
            <Typography>{totalDisabled}</Typography>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={24}>
          <Space
            style={{
              width: "100%",
            }}
          >
            <Input
              style={{ width: "300px" }}
              placeholder="Search Creator"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <Select
              style={{
                width: "150px",
              }}
              placeholder="Select User status"
              allowClear
              onChange={(value) => setSelectedStatus(value)}
              value={selectedStatus}
            >
              <Select.Option value="enabled">Enabled</Select.Option>
              <Select.Option value="requested">Requested</Select.Option>
              <Select.Option value="disabled">Disabled</Select.Option>
            </Select>
            <Select
              style={{ width: "150px" }}
              placeholder="Select Subscription status"
              allowClear
              onChange={(value) => setSubscriptionStatus(value)}
              value={subscriptionStatus}
            >
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(dates) => {
                setStartDate(dates?.[0]);
                setEndDate(dates?.[1]);
              }}
              placeholder={[
                "Subscription end date from",
                "Subscription end date to",
              ]}
            />
            <Select
              placeholder="Tier Autopay Status"
              allowClear
              value={autopayStatus}
              onSelect={setAutopayStatus}
              onClear={() => setAutopayStatus(null)}
            >
              {autopayStatuses.map((val) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
            <Button onClick={() => getWhatsappUsersData()}>Refresh</Button>
            <Button type="primary" onClick={() => getWhatsappUsersCSV()}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      {getWhatsappsTable()}
      <Modal
        title={<Typography>Set Up Config</Typography>}
        visible={showConfigModal}
        onCancel={() => {
          setShowConfigModal(false);
          setSelectedUser(null);
        }}
        onOk={() => {
          updateWhatsappConfigData();
        }}
      >
        <CreateConfigForm ref={formRef} config={selectedUser?.whatsappConfig} />
      </Modal>
      <Modal
        title={<Typography>Enablement</Typography>}
        visible={enablementModalVisible}
        onCancel={() => {
          setEnablementModalVisible(false);
          setEnablementState(null);
          setSelectedUser(null);
        }}
        onOk={() => {
          switch (enablementState) {
            case "enabled":
              handleEnableWhatsappConfig(selectedUser?._id);
              break;
            case "disabled":
              handleDisableWhatsappConfig(selectedUser?._id);
              break;
            default:
              break;
          }
        }}
      >
        <Typography>
          Are you sure you want to {enablementState} the user? This will{" "}
          {enablementState === "enabled" ? "enable" : "disable"}
          the users account and the user will be able to{" "}
          {enablementState === "enabled" ? "use" : "not use"} the whatsapp
          service.
        </Typography>
      </Modal>
      <Modal
        title="Set Tier Expiry"
        visible={showTierExpiryModal}
        onCancel={() => {
          setShowTierExpiryModal(false);
          setSelectedUser(null);
          setSelectedTierExpiryDate(null);
        }}
        onOk={() => {
          handleUpdateWhatsappExpiry(selectedUser?._id);
        }}
      >
        <DatePicker
          value={selectedTierExpiryDate}
          onChange={(date) => setSelectedTierExpiryDate(date)}
        />
      </Modal>
      <Modal
        visible={cancelSubscriptionModalVisible}
        onCancel={() => {
          setSelectedUser(null);
          setCancelSubscriptionModalVisible(false);
        }}
        onOk={() => {
          handleCancelWhatsappSubscription(selectedUser?._id);
        }}
        destroyOnClose
        title="Cancel Subscription?"
      >
        Are you sure you want to cancel the subscription for{" "}
        {selectedUser?.name}? THIS IS IRREVERSIBLE
      </Modal>
    </>
  );
};

export default WhatsappManagement;
