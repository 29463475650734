import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { getAffiliatesEarnings, getAllInfluencers } from "../api";
import AffiliateOrdersTable from "./AffiliateOrdersTable";

const { Option } = Select;

const Affiliates = () => {
  const [creators, setCreators] = useState([]);
  const [creatorQuery, setCreatorQuery] = useState("");
  const [search, setSearch] = useState("");
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [affiliates, setAffiliates] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [presentlyViewing, setPresentlyViewing] = useState("");
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const getCreatorSearch = () => {
    getAllInfluencers(creatorQuery, 1)
      .then((response) => {
        if (response.code === 0) {
          if (response.result.length) {
            setCreators(response.result);
          }
        } else if (response.code === 100) {
          message.error("Something went wrong");
        }
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  const getAffiliatesData = async () => {
    setLoading(true);
    try {
      const resp = await getAffiliatesEarnings(
        startDate,
        endDate,
        debouncedSearch,
        selectedCreator,
        page,
        10
      );
      if (resp.code === 0) {
        setAffiliates(resp.result?.data);
        setTotal(resp.result?.total);
        setTotalAmount(resp.result?.totalAmount);
        setTotalCommission(resp.result?.totalCommission);
        setTotalOrders(resp.result?.totalOrders);
        setTableKey(Date.now());
      } else {
        message.error(resp.message || "Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getAffiliatesCsv = async () => {
    try {
      const resp = await getAffiliatesEarnings(
        startDate,
        endDate,
        debouncedSearch,
        selectedCreator,
        undefined,
        undefined,
        true
      );
      fileDownload(resp, `affiliates_earnings_${moment().toISOString()}.csv`);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getAffiliatesTable = () => {
    const columns = [
      {
        title: "Affiliate",
        dataIndex: "affiliateId",
        key: "affiliateId",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.affiliateProfilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.affiliateName}
            </Typography>
            <Typography>{record?.affiliateEmail}</Typography>
            <Typography>{record?.affiliatePhone}</Typography>
          </Space>
        ),
      },
      {
        title: "Transaction Value",
        dataIndex: "totalAmount",
        key: "totalAmount",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "Commission",
        dataIndex: "totalCommission",
        key: "totalCommission",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "TM Commission",
        dataIndex: "totalTmCommission",
        key: "totalTmCommission",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "Orders",
        dataIndex: "totalOrders",
        key: "totalOrders",
      },
      {
        title: "Bank Account",
        dataIndex: "accountNumber",
        key: "accountNumber",
        render: (_, record) => {
          return (
            <Space direction="vertical">
              {record?.accountNumber ? (
                <>
                  <Typography>{record?.accountNumber}</Typography>
                  <Typography>{record?.ifsc}</Typography>
                  <Typography>{record?.accountName}</Typography>
                  <Typography>{record?.bankName}</Typography>
                </>
              ) : null}
              {record?.internationalBankAccount ? (
                <>
                  <Typography>
                    {record?.internationalBankAccount?.account_holder_name}
                  </Typography>
                  <Typography>
                    {record?.internationalBankAccount?.account_number}
                  </Typography>
                  <Typography>
                    {record?.internationalBankAccount?.bank_name}
                  </Typography>
                  <Typography>
                    {record?.internationalBankAccount?.country}
                  </Typography>
                </>
              ) : null}
            </Space>
          );
        },
      },
    ];
    return (
      <Table
        key={tableKey}
        columns={columns}
        dataSource={affiliates}
        pagination={{
          total,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
          pageSize: 10,
          position: ["bottomCenter"],
        }}
        loading={loading}
        style={{
          marginTop: "16px",
        }}
        rowKey={(record) => record?.affiliateId}
        expandable={{
          expandedRowRender: (record) => (
            <AffiliateOrdersTable
              affiliateId={record?.affiliateId}
              startDate={startDate}
              endDate={endDate}
            />
          ),
          expandedRowKeys: [presentlyViewing],
          onExpand: (expanded, record) => {
            if (expanded) {
              setPresentlyViewing(record?._id);
            } else {
              setPresentlyViewing("");
            }
          },
        }}
      />
    );
  };

  useEffect(() => {
    getCreatorSearch();
  }, [creatorQuery]);

  useEffect(() => {
    getAffiliatesData();
  }, [startDate, endDate, debouncedSearch, selectedCreator, page]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={6}>
          <Card title="Total Affiliate Txn" bordered={false}>
            <Typography>₹{totalAmount?.toFixed(2)}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Affiliate Commission" bordered={false}>
            <Typography>₹{totalCommission?.toFixed(2)}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Affiliates" bordered={false}>
            <Typography>{total}</Typography>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Orders" bordered={false}>
            <Typography>{totalOrders}</Typography>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={14}>
          <Row>
            <Col
              span={12}
              style={{
                paddingRight: "8px",
              }}
            >
              <Input
                minLength={2}
                className="searchbar"
                value={search}
                style={{ width: "100%", marginBottom: "4px" }}
                placeholder="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Col>
            <Col span={12}>
              <AutoComplete
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear
                onSelect={(value, record) => {
                  setSelectedCreator(record?.key);
                }}
                onSearch={(value) => setCreatorQuery(value)}
                placeholder="Enter Creator"
                style={{
                  width: "100%",
                }}
              >
                {creators?.map((eachDetails) => (
                  <Option
                    key={eachDetails?._id}
                    value={`${eachDetails?.name}-${eachDetails?._id}`}
                  >
                    <Space size="small">
                      <Avatar src={eachDetails?.profilePicUrl} size={32} />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          marginLeft: 6,
                        }}
                      >
                        {eachDetails?.name}{" "}
                        {eachDetails?.isWhitelabelled ? "(Whitelabel)" : ""}
                      </Typography>
                    </Space>
                  </Option>
                ))}
              </AutoComplete>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Space
            style={{
              paddingLeft: "8px",
            }}
          >
            <DatePicker.RangePicker
              onChange={(date) => {
                if (date) {
                  setStartDate(date[0].startOf("day"));
                  setEndDate(date[1].endOf("day"));
                } else {
                  setStartDate(moment().add(-7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              value={[startDate, endDate]}
            />
            <Button onClick={() => getAffiliatesData()}>Refresh</Button>
            <Button type="primary" onClick={() => getAffiliatesCsv()}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      {getAffiliatesTable()}
    </>
  );
};

export default Affiliates;
