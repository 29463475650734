import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";

import useDebounce from "../../hooks/useDebounce";
import { getTierOrders } from "../api";

const TierOrders = () => {
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tier, setTier] = useState([]);
  const [tableKey, setTableKey] = useState(Date.now());

  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="small" direction="vertical">
          <Space size="small">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.name}
            </Typography>
          </Space>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.email}
          </Typography>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.phone}
          </Typography>
          <Typography.Link>{record?.host}</Typography.Link>
        </Space>
      ),
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Space direction="vertical">
          <Typography>
            <strong>Amount (incl. GST):</strong> {record?.currency} {text}
          </Typography>
          <Typography>
            <strong>GST:</strong> {record?.gst}
          </Typography>
          <Typography>
            <strong>Convenience Fee:</strong> {record?.convenienceFee}
          </Typography>
          {record?.changepaymentMethod ? (
            <Typography>(Change Payment Method)</Typography>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" href={record?.invoice} target="_blank" block>
            Invoice
          </Button>
        </Space>
      ),
    },
  ];

  const handleFetchTierOrders = async () => {
    setLoading(true);
    try {
      const resp = await getTierOrders(
        startDate?.toISOString(),
        endDate?.toISOString(),
        debouncedSearch,
        tier,
        page,
        limit
      );
      if (resp?.code === 0) {
        setData(resp?.result?.data);
        setTotal(resp?.result?.total);
        setTotalAmount(resp?.result?.totalAmount);
        setTotalGst(resp?.result?.totalGst);
        setTableKey(Date.now());
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCSVDownload = async () => {
    try {
      const resp = await getTierOrders(
        startDate?.toISOString(),
        endDate?.toISOString(),
        debouncedSearch,
        tier,
        page,
        limit,
        true
      );
      fileDownload(resp, "tier_orders.csv");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    handleFetchTierOrders();
  }, [startDate, endDate, debouncedSearch, tier, page, limit]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={8}>
          <Card title="Total Amount (incl. GST)" bordered={false}>
            <Typography>₹{totalAmount}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total GST" bordered={false}>
            <Typography>₹{totalGst}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Orders" bordered={false}>
            <Typography>{total}</Typography>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={8}>
          <Input
            style={{ width: "100%" }}
            placeholder="Search Creator name, email, phone"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={16}>
          <Space>
            <DatePicker.RangePicker
              placeholder={["Start Date", "End Date"]}
              allowClear
              value={[startDate, endDate]}
              onChange={(values) => {
                setStartDate(values?.[0]?.startOf("day"));
                setEndDate(values?.[1]?.endOf("day"));
              }}
            />
            <Select
              mode="multiple"
              placeholder="Product"
              onChange={setTier}
              value={tier}
              allowClear
              style={{
                width: 150,
              }}
              onClear={() => setTier(null)}
            >
              <Select.Option value="tier2">Tier 2</Select.Option>
              <Select.Option value="tier3">Tier 3</Select.Option>
              <Select.Option value="tier4">Tier 4</Select.Option>
              <Select.Option value="whatsapp">Whatsapp</Select.Option>
            </Select>
            <Button type="primary" onClick={handleCSVDownload}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        key={tableKey}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          current: page,
          pageSize: limit,
          total,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: (p, pageSize) => {
            setPage(p);
            setLimit(pageSize);
          },
          onShowSizeChange: (p, pageSize) => {
            setPage(p);
            setLimit(pageSize);
          },
        }}
      />
    </>
  );
};

export default TierOrders;
