import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import debounce from "../../Utils/utilityFunctions";
import useDebounce from "../../hooks/useDebounce";
import { getTagmangoCreatorsData, upgradePlan } from "../api";
import CreatorDetailExpand from "./CreatorDetailExpand";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const { Option } = Select;

const TagmangoCreators = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [earningGt, setEarningGt] = useState(null);
  const [earningLt, setEarningLt] = useState(null);
  const [mangoCountGt, setMangoCountGt] = useState(null);
  const [mangoCountLt, setMangoCountLt] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [planUpgradeModalVisible, setPlanUpgradeModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const creatorsTable = () => {
    const columns = [
      {
        title: "Creator",
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return (
            <Space size="small" direction="horizontal">
              <Avatar src={record?.profilePicUrl} size={32} />
              <Typography style={nameStyle}>{record?.name}</Typography>
            </Space>
          );
        },
      },
      {
        title: "Mango Count",
        dataIndex: "mangoCount",
        key: "mangoCount",
      },
      {
        title: "Total Earnings",
        dataIndex: "totalEarning",
        key: "totalEarning",
        render: (text) => text?.toFixed(2),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => {
          return !record?.whitelabelPlanType ? (
            <Space size="small" direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  setSelectedUser(record);
                  setPlanUpgradeModalVisible(true);
                }}
              >
                Upgrade Plan
              </Button>
            </Space>
          ) : null;
        },
      },
    ];

    return (
      <Table
        key={tableKey}
        columns={columns}
        dataSource={users}
        pagination={{
          current: page,
          total,
          onChange: (pg) => setPage(pg),
        }}
        loading={loading}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => (
            <CreatorDetailExpand creatorId={record?._id} />
          ),
        }}
      />
    );
  };

  const handleGetTagmangoCreators = async () => {
    setLoading(true);
    try {
      const resp = await getTagmangoCreatorsData(
        debouncedSearch,
        startDate,
        endDate,
        earningGt,
        earningLt,
        mangoCountGt,
        mangoCountLt,
        page,
        10,
        false
      );
      if (resp.code === 0) {
        setUsers(resp?.result?.data);
        setTotal(resp?.result?.total);
        setTableKey(Date.now());
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleGetTagmangoCreatorsCSV = async () => {
    try {
      const resp = await getTagmangoCreatorsData(
        debouncedSearch,
        startDate,
        endDate,
        earningGt,
        earningLt,
        mangoCountGt,
        mangoCountLt,
        page,
        10,
        true
      );
      fileDownload(resp, `tagmango-creators-${Date.now()}.csv`);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handlePlanUpgrade = async (userId, planType) => {
    const hide = message.loading("Upgrading plan...");
    try {
      const resp = await upgradePlan({ userId, planType });
      if (resp.code === 0) {
        message.success("Plan upgraded successfully");
        setPlanUpgradeModalVisible(false);
        setSelectedUser(null);
        setSelectedPlan(null);
        handleGetTagmangoCreators();
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  useEffect(() => {
    handleGetTagmangoCreators();
  }, [
    debouncedSearch,
    search,
    startDate,
    endDate,
    earningGt,
    earningLt,
    mangoCountGt,
    mangoCountLt,
  ]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={16}>
          <Input
            minLength={2}
            value={search}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search by name, email, phone"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
        <Col span={8}>
          <Space>
            <Button type="primary" onClick={handleGetTagmangoCreatorsCSV}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={8}>
          <Space>
            <Typography>Amount Made:</Typography>
            <InputNumber value={earningGt} onChange={debounce(setEarningGt)} />
            <Typography>To:</Typography>
            <InputNumber value={earningLt} onChange={debounce(setEarningLt)} />
          </Space>
        </Col>
        <Col span={8}>
          <Space>
            <Typography>Number of Mangoes Made:</Typography>
            <InputNumber
              value={mangoCountGt}
              onChange={debounce(setMangoCountGt)}
            />
            <Typography>To:</Typography>
            <InputNumber
              value={mangoCountLt}
              onChange={debounce(setMangoCountLt)}
            />
          </Space>
        </Col>
        <Col
          span={8}
          style={{
            height: "100%",
          }}
        >
          <Space>
            <Typography>Created At: </Typography>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(values) => {
                setStartDate(values?.[0]);
                setEndDate(values?.[1]);
              }}
            />
          </Space>
        </Col>
      </Row>
      {creatorsTable()}
      <Modal
        title="Upgrade Plan"
        visible={planUpgradeModalVisible}
        onCancel={() => setPlanUpgradeModalVisible(false)}
        footer={null}
      >
        <Typography>
          Are you sure you want to upgrade the plan for {selectedUser?.name}?
        </Typography>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Select
            style={{
              width: "200px",
            }}
            value={selectedPlan}
            onChange={(value) => setSelectedPlan(value)}
          >
            <Option value="freedom-plan">Freedom Plan</Option>
            <Option value="enterprise-plan">Enterprise Plan</Option>
          </Select>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                if (selectedPlan) {
                  handlePlanUpgrade(selectedUser?._id, selectedPlan);
                } else {
                  message.error("Please select a plan");
                }
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setPlanUpgradeModalVisible(false);
                setSelectedUser(null);
                setSelectedPlan(null);
              }}
            >
              No
            </Button>
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default TagmangoCreators;
