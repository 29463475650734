import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { getDateRangeEarningV2 } from "../api/index";
import RefundHandler from "./RefundHandler";

const { Option } = Select;

const currencyMappings = {
  INR: "₹",
  USD: "$",
  EUR: "€",
};

const OrderDetailsV2 = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [searchQuery, setSearchQuery] = useState("");
  const [orders, setOrders] = useState();
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [earning, setEarning] = useState();
  const [orderCount, setOrderCount] = useState();
  const [showLifetimeOrders, setShowLifetimeOrders] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [orderStatus, setOrderStatus] = useState("completed");
  const [searchFields, setSearchFields] = useState([]);
  const [showZapier, setShowZapier] = useState(false);
  const [showMoengageOrders, setShowMoengageOrders] = useState(false);
  const debouncedSearch = useDebounce({
    value: searchQuery,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const errorHandle = (error) => {
    if (error.status === 401 || error.code === 401) {
      localStorage.clear();
      history.push("/");
      return error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
    console.log(error);
    return error?.message || error?.result
      ? message.error(error?.message || error?.result)
      : message.error("Something went wrong");
  };

  const downloadCSV = async () => {
    const hide = message.loading("Downloading CSV...");
    try {
      const response = await getDateRangeEarningV2(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        searchFields,
        orderStatus,
        showZapier,
        showMoengageOrders,
        true
      );
      fileDownload(
        response,
        `${startDate.toISOString()}-${endDate.toISOString()}-${
          debouncedSearch !== "" ? debouncedSearch : ""
        }-orders.csv`
      );
    } catch (error) {
      errorHandle(error);
    } finally {
      hide();
    }
  };

  function getRefundAmountForUser(record, onlyRefundAmount = false) {
    let orderAmount = 0;
    let refundAmount = 0;
    if (!record?.refundAmount) return record?.amount || 0;
    if (!record?.subscriberCurrency) {
      orderAmount = record?.inr?.afterGstAmount || 0;
      refundAmount = record.refundAmount.inrAmount ?? 0;
    } else {
      switch (record.subscriberCurrency) {
        case "INR":
          orderAmount = record?.inr?.afterGstAmount || 0;
          refundAmount = record.refundAmount.inrAmount ?? 0;
          break;
        case "USD":
          orderAmount = record?.usd?.afterGstAmount || 0;
          refundAmount = record.refundAmount.usdAmount ?? 0;
          break;
        case "EUR":
          orderAmount = record?.eur?.afterGstAmount || 0;
          refundAmount = record.refundAmount.eurAmount ?? 0;
          break;
        default:
          orderAmount = 0;
          refundAmount = 0;
          break;
      }
    }
    if (onlyRefundAmount) return refundAmount;
    return (orderAmount - refundAmount).toFixed(2);
  }

  useEffect(async () => {
    setOrdersLoading(true);
    try {
      const res = await getDateRangeEarningV2(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        searchFields,
        orderStatus,
        showZapier,
        showMoengageOrders,
        false,
        page
      );
      if (res?.code === 0) {
        setOrders(res.result?.orders);
        setOrderCount(res.result?.orderCount);
        setTotalPages(Math.ceil(res?.result?.orderCount / 100));
        setEarning(res.result?.totalEarning);
        setOrdersLoading(false);
        setTableKey(Date.now());
        window.scrollTo(0, 0);
      } else {
        errorHandle(res);
      }
    } catch (error) {
      errorHandle(error);
    }
  }, [
    startDate,
    endDate,
    debouncedSearch,
    page,
    orderStatus,
    showZapier,
    searchFields,
    showMoengageOrders,
  ]);

  useEffect(() => {
    if (showLifetimeOrders) {
      setStartDate(moment("2020-12-28 14:30:14.755Z").startOf("day"));
    } else {
      setStartDate(moment().add(-7, "days").startOf("day"));
    }
  }, [showLifetimeOrders]);

  const orderStatusMenu = () => {
    return (
      <Select
        defaultValue="completed"
        onChange={(value) => setOrderStatus(value)}
      >
        <Option value="completed">Completed</Option>
        <Option value="initiated">Initiated</Option>
        <Option value="uniquelyinitiated">Uniquely Initiated</Option>
        <Option value="refunded">Refunded</Option>
        <Option value="partiallyrefunded">Partially Refunded</Option>
      </Select>
    );
  };

  const searchFieldsMenu = () => {
    return (
      <Select
        mode="multiple"
        placeholder="Search Fields"
        style={{ width: "100%" }}
        maxTagCount={2}
        allowClear
        onChange={(value) => setSearchFields(value)}
      >
        <Option value="creatorName">Creator Name</Option>
        <Option value="fanName">Fan Name</Option>
        <Option value="fanEmail">Fan Email</Option>
        <Option value="fanPhone">Fan Phone</Option>
        <Option value="mangoName">Mango Name</Option>
        <Option value="coupon">Coupon</Option>
      </Select>
    );
  };

  const checkForFullRefund = (record) => {
    let afterConvienceFee = 0;
    let refundAmount = 0;
    switch (record.subscriberCurrency) {
      case "INR":
        afterConvienceFee = record.inr.afterConvenienceAmount;
        refundAmount = record.refundAmount.inrAmount;
        break;
      case "USD":
        afterConvienceFee = record.usd.afterConvenienceAmount;
        refundAmount = record.refundAmount.usdAmount;
        break;
      case "EUR":
        afterConvienceFee = record.eur.afterConvenienceAmount;
        refundAmount = record.refundAmount.eurAmount;
        break;
      default:
        afterConvienceFee = record.inr.afterConvenienceAmount;
        refundAmount = record.refundAmount.inrAmount;
        break;
    }

    if (record.partiallyRefunded) return false;
    if (Math.floor(refundAmount) === Math.floor(afterConvienceFee)) return true;
    return false;
  };

  const ordersTable = () => {
    const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };
    const columnData = [
      {
        title: "Order ID",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Creator",
        dataIndex: "creatorName",
        key: "creatorName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.creatorProfilePicUrl} size={32} />
            <Typography style={nameStyle}>{record?.creatorName}</Typography>
            <Typography>{record?.mangoName}</Typography>
            <Typography style={{ color: "#1890ff" }}>
              {record?.isZapier ? "(Landing Page)" : ""}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Subscriber",
        dataIndex: "fanName",
        key: "fanName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.fanImg} size={32} />
            <Typography style={nameStyle}>{record?.fanName}</Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanEmail}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanPhone}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              Country: {record?.fanCountry ? record?.fanCountry : "N/A"}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography>
              {currencyMappings[record?.subscriberCurrency || "INR"]}
              {record?.amount}
            </Typography>
            {record?.gst ? (
              <Typography>
                <strong>GST: </strong>
                {currencyMappings[record?.subscriberCurrency || "INR"]}
                {record?.gst}
              </Typography>
            ) : null}
            {record?.convenienceFee ? (
              <Typography>
                <strong>Convenience Fee:</strong>
                {currencyMappings[record?.subscriberCurrency || "INR"]}
                {record?.convenienceFee}
              </Typography>
            ) : null}
            <Typography>
              <strong>Comission charged: </strong>
              {currencyMappings[record?.subscriberCurrency || "INR"]}
              {
                record[(record?.subscriberCurrency || "INR")?.toLowerCase()]
                  ?.commissionCharged
              }
            </Typography>
            {record?.quantity ? (
              <Typography>
                <strong>Quantity: </strong>
                {record?.quantity}
              </Typography>
            ) : null}
          </Space>
        ),
      },
      {
        title: "Coupon",
        dataIndex: "coupon",
        key: "coupon",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography>{record?.coupon ? record?.coupon : "N/A"}</Typography>
            <Typography>
              {record?.discount ? `(-${record?.discount})` : ""}{" "}
            </Typography>
            <Typography style={{ color: "#1890ff" }}>
              {record?.moengageCouponUsed ? "(Moengage)" : ""}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Order Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <Typography>
            {moment(record?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
      {
        title: "Order Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Typography>
            {record?.refundState &&
            record?.refundState === "completed" &&
            record?.status === "refunded"
              ? `${
                  checkForFullRefund(record)
                    ? "Full Refunded"
                    : "Partially Refunded"
                }`
              : record?.status}
          </Typography>
          // <Typography>
          //   {record?.refundState
          //     ? `${
          //         record?.refundState === "completed"
          //           ? record?.status
          //           : `Refund ${record?.refundState}`
          //       }`
          //     : record?.status}
          // </Typography>
        ),
      },
      {
        title: "Refund Date",
        dataIndex: "refundInitiatedAt",
        key: "refundInitiatedAt",
        render: (text, record) => (
          <Typography>
            {record.refundInitiatedAt
              ? moment(record.refundInitiatedAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )
              : "N/A"}
          </Typography>
        ),
      },
      {
        title: "Refund Amount",
        render: (record) => {
          if (!record?.refundAmount) return <Typography>N/A</Typography>;
          const refundAmount = getRefundAmountForUser(record, true);
          return (
            <Typography>
              {currencyMappings[record?.subscriberCurrency || "INR"]}
              {refundAmount}
            </Typography>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 200,
        fixed: "right",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            {record?.invoice && (
              <Button
                type="primary"
                href={record?.invoice}
                target="_blank"
                block
              >
                Invoice
              </Button>
            )}
            {record?.withdrawalStatus !== "ongoing" &&
            record?.withdrawalStatus !== "withdrawn" ? (
              <RefundHandler
                orderId={record?._id}
                name={record?.fanName}
                email={record?.fanEmail}
                phone={record?.fanPhone}
                amountToRefund={getRefundAmountForUser(record)}
                refundState={record?.refundState}
                refundCurrency={record?.subscriberCurrency}
                isPartialRefunded={record?.partiallyRefunded || false}
                isGSTEnabled={record?.gst}
              />
            ) : null}
          </Space>
        ),
      },
    ];
    return (
      <>
        <div>
          <Table
            key={tableKey}
            columns={columnData}
            dataSource={orders}
            loading={ordersLoading}
            pagination={{
              current: page,
              pageSize: 100,
              onChange: setPage,
              total: totalPages * 100,
              showSizeChanger: false,
              showTotal: (total) => `Total ${total} orders`,
            }}
            scroll={{ x: 2500 }}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={8}>
          <Card title="Total Earnings" bordered={false}>
            <Typography>₹ {earning}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Orders" bordered={false}>
            <Typography>{orderCount}</Typography>
          </Card>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: "8px" }}>
            <Input
              minLength={2}
              value={searchQuery}
              className="searchbar"
              style={{ width: "75%" }}
              placeholder="Search"
              onChange={(event) => setSearchQuery(event.target.value)}
            />
            <div style={{ width: "25%" }}>{searchFieldsMenu()}</div>
          </div>
          <DatePicker.RangePicker
            disabled={showLifetimeOrders}
            onChange={(date) => {
              if (date) {
                setStartDate(date[0].startOf("day"));
                setEndDate(date[1].endOf("day"));
              } else {
                setStartDate(moment().add(-7, "days").startOf("day"));
                setEndDate(moment().endOf("day"));
              }
            }}
            value={[startDate, endDate]}
            style={{ marginBottom: "8px" }}
          />
          <div style={{ display: "flex", width: "100%", marginBottom: "8px" }}>
            <Checkbox
              onChange={() => {
                setShowLifetimeOrders(!showLifetimeOrders);
              }}
            >
              Show Lifetime Orders
            </Checkbox>
            <Checkbox
              onChange={() => {
                setShowZapier(!showZapier);
              }}
            >
              Show Creator Landing Orders
            </Checkbox>
            <Checkbox
              onChange={() => {
                setShowMoengageOrders(!showMoengageOrders);
              }}
            >
              Show Moengage Orders
            </Checkbox>
            <div>{orderStatusMenu()}</div>
          </div>
          <Button
            type="primary"
            onClick={() => {
              downloadCSV();
            }}
          >
            Download CSV
          </Button>
        </Col>
      </Row>
      {ordersTable()}
    </>
  );
};

export default OrderDetailsV2;
