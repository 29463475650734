import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import DetailsExpand from "../../Components/RefernEarnDetailsExpand";
import useDebounce from "../../hooks/useDebounce";
import { createRefernEarnPayout, getRefernEarnEarnings } from "../api";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const RefernEarnEarnings = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [payoutApprovalModalVisible, setPayoutApprovalModalVisible] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [approvedPayoutAmount, setApprovedPayoutAmount] = useState(0);
  const [payoutGST, setPayoutGST] = useState(0);
  const [payoutApproving, setPayoutApproving] = useState(false);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const handleGetRefernEarnEarnings = async () => {
    setLoading(true);
    try {
      const res = await getRefernEarnEarnings(
        startDate?.toISOString(),
        endDate?.toISOString(),
        debouncedSearch,
        page,
        limit
      );
      if (res?.code === 0) {
        setData(res.result?.data);
        setTotal(res.result?.total);
        setTableKey(Date.now());
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleGetRefernEarnEarningsCSV = async () => {
    const hide = message.loading("Generating CSV...", 0);
    try {
      const res = await getRefernEarnEarnings(
        startDate?.toISOString(),
        endDate?.toISOString(),
        debouncedSearch,
        page,
        limit,
        true
      );
      fileDownload(res, "refern_earn_earnings.csv");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  const handleRefernEarnPayoutCreation = async () => {
    setPayoutApproving(true);
    try {
      const resp = await createRefernEarnPayout({
        user: selectedUser?._id,
        amount: approvedPayoutAmount,
        additionalGst: selectedUser?.gstNumber ? payoutGST : 0,
      });
      if (resp?.code === 0) {
        message.success("Payout Approved");
        setPayoutApprovalModalVisible(false);
        setSelectedUser(null);
        setApprovedPayoutAmount(0);
        setPayoutGST(0);
        handleGetRefernEarnEarnings();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setPayoutApproving(false);
    }
  };

  const columns = [
    {
      title: "Creator Details",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="small" direction="vertical">
          <Avatar src={record?.profilePicUrl} size={32} />
          <Typography style={nameStyle}>{record?.name}</Typography>
          <Typography>{record?.email}</Typography>
          <Typography>{record?.phone}</Typography>
          <Typography>({record?.host})</Typography>
        </Space>
      ),
    },
    {
      title: "Total Product Value",
      dataIndex: "productValue",
      key: "productValue",
    },
    {
      title: "Earnings",
      dataIndex: "earnings",
      key: "earnings",
    },
    {
      title: "Total Paid Out",
      dataIndex: "totalPaidOut",
      key: "totalPaidOut",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => {
              setSelectedUser(record);
              setPayoutApprovalModalVisible(true);
            }}
          >
            Approve
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    handleGetRefernEarnEarnings();
  }, [page, limit, debouncedSearch, startDate, endDate]);

  return (
    <>
      <Row>
        <Col span={12}>
          <Input
            minLength={2}
            className="searchbar"
            value={search}
            style={{ width: "100%" }}
            placeholder="Search a creator"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
        <Col span={12}>
          <Space>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(value) => {
                setStartDate(value?.[0]);
                setEndDate(value?.[1]);
              }}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => {
                handleGetRefernEarnEarningsCSV();
              }}
            >
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        key={tableKey}
        columns={columns}
        dataSource={data}
        loading={loading}
        style={{
          marginTop: "20px",
        }}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total,
          pageSize: limit,
          onChange: (p) => setPage(p),
          showSizeChanger: true,
          onShowSizeChange: (p, l) => {
            setPage(p);
            setLimit(l);
          },
        }}
        expandable={{
          expandedRowRender: (record) => <DetailsExpand userDetails={record} />,
        }}
      />
      <Modal
        title="Payout Approval"
        visible={payoutApprovalModalVisible}
        destroyOnClose
        onCancel={() => {
          setPayoutApprovalModalVisible(false);
          setSelectedUser(null);
        }}
        onOk={handleRefernEarnPayoutCreation}
        okButtonProps={{
          loading: payoutApproving,
          disabled: approvedPayoutAmount === 0,
        }}
      >
        <Space direction="vertical" size="large">
          <Typography>Approve Payout for {selectedUser?.name}</Typography>
          <Space>
            <Typography>Amount:</Typography>
            <InputNumber
              onChange={(value) => {
                setApprovedPayoutAmount(value);
                if (selectedUser?.gstNumber) {
                  setPayoutGST(value * 0.18);
                }
              }}
              value={approvedPayoutAmount}
              max={selectedUser?.balance}
              min={0}
            />
          </Space>
          {selectedUser?.gstNumber && (
            <Space>
              <Typography>GST ({selectedUser?.gstNumber}):</Typography>
              <InputNumber
                onChange={(value) => setPayoutGST(value)}
                value={payoutGST}
                min={0}
              />
            </Space>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default RefernEarnEarnings;
