/* eslint-disable no-unused-vars */
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { getUpcomingMangos } from "../api";

const UpcomingMangos = () => {
  const [upcomingMangos, setUpcomingMangos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const getUpcomingMangosData = async () => {
    setLoading(true);
    try {
      const resp = await getUpcomingMangos(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        page,
        limit
      );
      if (resp.code === 0) {
        setUpcomingMangos(resp?.result?.data || []);
        setTotal(resp?.result?.total || 0);
        setTableKey(Date.now());
      } else {
        message.error("Something went wrong" || resp?.message);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const mangosTable = () => {
    const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };
    const columnData = [
      {
        title: "Creator Name",
        dataIndex: "creatorName",
        key: "creatorName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography style={nameStyle}>{record?.creatorName}</Typography>
          </Space>
        ),
      },
      {
        title: "Mango Name",
        dataIndex: "mangoName",
        key: "mangoName",
      },
      {
        title: "Starting on",
        dataIndex: "startDate",
        key: "startDate",
        render: (text, record) => (
          <Typography>
            {moment(record?.startDate).format("DD MMM YYYY h:mm a")}
          </Typography>
        ),
      },
    ];
    return (
      <Table
        key={tableKey}
        columns={columnData}
        dataSource={upcomingMangos}
        loading={loading}
        pagination={{
          pageSize: limit,
          current: page,
          total,
          onChange: (p, l) => {
            setPage(p);
            setLimit(l);
          },
        }}
      />
    );
  };

  useEffect(() => {
    getUpcomingMangosData();
  }, [page, limit, startDate, endDate, debouncedSearch]);

  return (
    <>
      <Row
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={12}>
          <Input
            style={{ width: "100%" }}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Col>
        <Col span={12}>
          <Space size="small">
            <DatePicker.RangePicker
              defaultValue={[startDate, endDate]}
              style={{
                marginLeft: "10px",
              }}
              onChange={([d1, d2]) => {
                if (d1 && d2) {
                  setStartDate(d1);
                  setEndDate(d2);
                } else {
                  setStartDate(moment().subtract(7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
            />
          </Space>
        </Col>
      </Row>
      {mangosTable()}
    </>
  );
};

export default UpcomingMangos;
