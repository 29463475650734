/* eslint-disable react/prop-types */
import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { getCohortSheet, getCreatorsEarnings } from "../api";
import CreatorNatureAction from "./CreatorNatureAction";
import MangoExpandTable from "./MangoExpandTable";

const CreatorEarnings = () => {
  const history = useHistory();
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [creatorCount, setCreatorCount] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalComissions, setTotalComissions] = useState(0);
  const [totalConvenienceFee, setTotalConvenienceFee] = useState(0);
  // const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showLifetimeEarnings, setShowLifetimeEarnings] = useState(false);
  const [natureFilter, setNatureFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("all");
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const controlsElementsStyle = {
    display: "flex",
    width: "100%",
    marginBottom: "8px",
  };

  const errorHandle = (error) => {
    if (error.status === 401 || error.code === 401) {
      localStorage.clear();
      history.push("/");
      return error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
    console.log(error);
    return error?.message || error?.result
      ? message.error(error?.message || error?.result)
      : message.error("Something went wrong");
  };

  const downloadCSV = async () => {
    const resp = await getCreatorsEarnings(
      startDate.toISOString(),
      endDate.toISOString(),
      debouncedSearch,
      natureFilter,
      typeFilter,
      true
    );
    fileDownload(
      resp,
      `${startDate.toISOString()}-${endDate.toISOString()}-${
        search !== "" ? search : ""
      }-creators.csv`
    );
  };

  const downloadCohortCSV = async () => {
    const resp = await getCohortSheet();
    fileDownload(resp, `cohort-sheet.csv`);
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const resp = await getCreatorsEarnings(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        natureFilter,
        typeFilter,
        false,
        page
      );
      if (resp?.code === 0) {
        setCreators(resp.result.creators);
        setCreatorCount(resp.result.creatorCount);
        setTotalPages(Math.ceil(resp.result.creatorCount / 100));
        setTotalEarning(resp.result?.totalEarning);
        setTotalComissions(resp.result?.totalComissions);
        setTotalConvenienceFee(resp.result?.totalConvenienceFee);
        // setTotalOrders(resp.result.totalOrders);
        setLoading(false);
        setTableKey(Date.now());
      } else {
        message.error(resp?.message || "Something went wrong");
        setLoading(false);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, [page, debouncedSearch, startDate, endDate, natureFilter, typeFilter]);

  useEffect(() => {
    if (showLifetimeEarnings) {
      setStartDate(moment("2020-12-28 14:30:14.755Z").startOf("day"));
    } else {
      setStartDate(moment().add(-7, "days").startOf("day"));
    }
  }, [showLifetimeEarnings]);

  const creatorsTable = () => {
    const columnData = [
      {
        title: "Creator",
        dataIndex: "_id",
        key: "_id",
        render: (text, record) => (
          <Space size="small">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.name} {record?.isWhitelabelled ? "(Whitelabelled)" : ""}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Mangoes",
        dataIndex: "nMangos",
        key: "nMangos",
      },
      {
        title: "Earnings",
        dataIndex: "earning",
        key: "earning",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "GST",
        dataIndex: "gst",
        key: "gst",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "Comissions",
        dataIndex: "comissions",
        key: "comissions",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "Convenience Fee",
        dataIndex: "convenienceFee",
        key: "convenienceFee",
        render: (text) => <Typography>{text?.toFixed(2)}</Typography>,
      },
      {
        title: "Orders",
        dataIndex: "totalOrders",
        key: "totalOrders",
      },
    ];

    const creatorNatureData = {
      title: "Creator Nature",
      dataIndex: "creatorNature",
      key: "creatorNature",
      render: (text, record) => (
        <CreatorNatureAction
          userId={record?._id}
          presentNature={record?.creatorNature || "unassigned"}
          onNatureChange={(nature) => {
            setCreators((prevState) =>
              prevState.map((creator) => {
                if (creator._id === record._id) {
                  return { ...creator, creatorNature: nature };
                }
                return creator;
              })
            );
          }}
        />
      ),
    };

    return (
      <>
        <div
          style={{
            display: "grid",
          }}
        >
          {loading ? (
            <LoadingOutlined
              style={{
                fontSize: "50px",
                alignSelf: "center",
                justifySelf: "center",
              }}
            />
          ) : (
            <div>
              <Table
                key={tableKey}
                columns={[...columnData, creatorNatureData]}
                dataSource={creators}
                pagination={{ pageSize: 100, hideOnSinglePage: true }}
                rowKey={(record) => record._id}
                expandable={{
                  expandedRowRender: (record) => {
                    const { mangos } = record;
                    return <MangoExpandTable mangos={mangos} />;
                  },
                }}
              />
              <Pagination
                current={page}
                pageSize={100}
                pageSizeOptions={["100"]}
                total={totalPages * 100}
                onChange={(value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={4}>
          <Card title="Total Transaction Value" bordered={false}>
            <Typography>₹ {totalEarning}</Typography>
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Total Comissions" bordered={false}>
            <Typography>₹ {totalComissions}</Typography>
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Total Convenience Fee" bordered={false}>
            <Typography>₹ {totalConvenienceFee}</Typography>
          </Card>
        </Col>
        <Col span={4}>
          <Card title="Total Creators" bordered={false}>
            <Typography>{creatorCount}</Typography>
          </Card>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={controlsElementsStyle}>
            <Input
              minLength={2}
              value={search}
              className="searchbar"
              style={{ width: "100%", marginBottom: "4px" }}
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "8px",
              justifyContent: "center",
            }}
          >
            <DatePicker.RangePicker
              disabled={showLifetimeEarnings}
              onChange={(date) => {
                if (date) {
                  setStartDate(date[0].startOf("day"));
                  setEndDate(date[1].endOf("day"));
                } else {
                  setStartDate(moment().add(-7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              value={[startDate, endDate]}
            />
          </div>
          <div
            style={{
              ...controlsElementsStyle,
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ width: "33%" }}
              onChange={() => {
                setShowLifetimeEarnings(!showLifetimeEarnings);
              }}
            >
              Show Lifetime Earnings
            </Checkbox>
            <Select
              style={{ width: "33%" }}
              placeholder="Creator Nature Filter"
              allowClear
              onChange={setNatureFilter}
              mode="multiple"
            >
              <Select.Option value="organic">Organic</Select.Option>
              <Select.Option value="growth">Growth</Select.Option>
              <Select.Option value="retention">Retention</Select.Option>
              <Select.Option value="mia">MIA</Select.Option>
            </Select>
            <Select
              style={{ width: "33%" }}
              placeholder="Creator Type Filter"
              allowClear
              onChange={setTypeFilter}
              value={typeFilter}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="whitelabelled">Whitelabel</Select.Option>
              <Select.Option value="tagmango">TagMango</Select.Option>
            </Select>
          </div>
          <div
            style={{
              ...controlsElementsStyle,
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                downloadCSV();
              }}
              style={{ marginRight: "4px" }}
            >
              Download CSV
            </Button>
            <Button
              type="primary"
              onClick={() => {
                downloadCohortCSV();
              }}
            >
              Download Cohort Sheet
            </Button>
          </div>
        </Col>
      </Row>
      {creatorsTable()}
    </>
  );
};

export default CreatorEarnings;
