/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { DebounceInput } from "react-debounce-input";

import fileDownload from "js-file-download";
import useDebounce from "../../hooks/useDebounce";
import { getOnboardingData, getOnboardingStats } from "../api";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const { Option } = Select;

const OnboardingDetails = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalSignupCounts, setTotalSignupCounts] = useState(0);
  const [totalOnboardingFormfillupCounts, setTotalOnboardingFormfillupCounts] =
    useState(0);
  const [totalFreedomPlanPurchaseCount, setTotalFreedomPlanPurchaseCount] =
    useState(0);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const creatorsTable = () => {
    const columns = [
      {
        title: "Creator",
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return (
            <>
              <Space size="small" direction="horizontal">
                <Avatar src={record?.profilePicUrl} size={32} />
                <Typography style={nameStyle}>{record?.name}</Typography>
              </Space>
            </>
          );
        },
      },
      {
        title: "Host",
        dataIndex: "host",
        key: "host",
        render: (text) => (
          <Typography.Link href={`https://${text}`}>{text}</Typography.Link>
        ),
      },
      {
        title: "Stage",
        dataIndex: "stage",
        key: "stage",
      },
      {
        title: "Annual Revenue",
        dataIndex: "annualRevenue",
        key: "annualRevenue",
      },
      {
        title: "Referrer",
        dataIndex: "referrer",
        key: "referrer",
      },
      {
        title: "Interested in WL",
        dataIndex: "interestedInWL",
        key: "interestedInWL",
      },
      {
        title: "Instagram Profile",
        dataIndex: "instagram_profile",
        key: "instagram_profile",
        render: (text) =>
          text ? (
            <Typography.Link href={text}>{text}</Typography.Link>
          ) : (
            <Typography>N/A</Typography>
          ),
      },
      {
        title: "Youtube Profile",
        dataIndex: "youtube_profile",
        key: "youtube_profile",
        render: (text) =>
          text ? (
            <Typography.Link href={text}>{text}</Typography.Link>
          ) : (
            <Typography>N/A</Typography>
          ),
      },
    ];

    return (
      <Table
        key={tableKey}
        columns={columns}
        dataSource={users}
        pagination={{
          current: page,
          total,
          onChange: (pg) => setPage(pg),
        }}
        loading={loading}
        rowKey={(record) => record._id}
      />
    );
  };

  const handleGetWLCreatorsCSV = async () => {
    try {
      const resp = await getOnboardingData(
        debouncedSearch,
        startDate,
        endDate,
        page,
        10,
        true
      );
      fileDownload(resp, `user-onboarding-data-${Date.now()}.csv`);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleGetOnboardingDataStats = async () => {
    try {
      const resp = await getOnboardingStats(
        startDate,
        endDate,
        debouncedSearch
      );
      console.log(resp);
      if (resp.code === 0) {
        setTotalSignupCounts(resp?.result?.totalSignup);
        setTotalOnboardingFormfillupCounts(resp?.result?.totalOnboarding);
        setTotalFreedomPlanPurchaseCount(
          resp?.result?.totalFreedomPlanPurchase
        );
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const handleGetOnboardingData = async () => {
    setLoading(true);
    try {
      const resp = await getOnboardingData(
        debouncedSearch,
        startDate,
        endDate,
        page,
        10
      );
      if (resp.code === 0) {
        setUsers(resp?.result?.data);
        setTotal(resp?.result?.total);
        setTableKey(Date.now());
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOnboardingDataStats();
  }, [startDate, endDate, debouncedSearch]);

  useEffect(() => {
    handleGetOnboardingData();
  }, [page, debouncedSearch, startDate, endDate]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={8}>
          <Card title="Total Signups">
            <Typography>{totalSignupCounts}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Onboarding Form Fills">
            <Typography>{totalOnboardingFormfillupCounts}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total FreedomPlan Purchase">
            <Typography>{totalFreedomPlanPurchaseCount}</Typography>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={16}>
          <Input
            minLength={2}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search by name, host"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={24}>
          <Space>
            <DatePicker.RangePicker
              placeholder={["Start Date", "End Date"]}
              allowClear
              value={[startDate, endDate]}
              onChange={(values) => {
                setStartDate(values?.[0]);
                setEndDate(values?.[1]);
              }}
            />
          </Space>
        </Col>
        <Col span={8}>
          <Space>
            <Button type="primary" onClick={handleGetWLCreatorsCSV}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      {creatorsTable()}
    </>
  );
};

export default OnboardingDetails;
