/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createRefund } from "../api";

const { Option } = Select;

const RefundHandler = ({
  orderId,
  name,
  email,
  phone,
  refundState,
  useManual,
  refundCurrency,
  amountToRefund,
  isPartialRefunded,
  isGSTEnabled,
}) => {
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [isPartialRefundAmountEnabled, setIsPartialRefundAmountEnabled] =
    useState(false);
  const [form] = Form.useForm();

  const handleRefund = async (values) => {
    setRefundLoading(true);

    try {
      const reqBody = {
        refundNotes: values?.refundNotes,
        orderId,
        manualRefundPhotoProof: values?.manualRefundPhotoProof?.file,
        refundCurrency,
        refundAmount: values?.partialRefundAmountEnabled
          ? isGSTEnabled
            ? Number((Number(values?.refundAmount) / 1.18).toFixed(2))
            : values?.refundAmount
          : null,
        allowPartialRefund: values?.partialRefundAmountEnabled || false,
      };

      const formBody = new FormData();
      Object.keys(reqBody).forEach((key) => {
        if (reqBody[key]) {
          formBody.append(key, reqBody[key]);
        }
      });
      const resp = await createRefund(formBody);
      if (resp?.code === 0) {
        message.success("Refund created successfully");
        setShowRefundModal(false);
      } else {
        message.error(resp?.message);
      }
    } catch (err) {
      message.error(err.message || "Error creating refund");
    }
    setRefundLoading(false);
    setShowRefundModal(false);
    setIsPartialRefundAmountEnabled(false);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      refundType: useManual ? "manual" : "automatic",
    });
  }, [showRefundModal]);

  return (
    <>
      <Button
        onClick={() => setShowRefundModal(true)}
        disabled={
          useManual
            ? false
            : refundState === "ongoing" ||
              (refundState === "completed" && !isPartialRefunded)
        }
        type="danger"
      >
        Refund Order
      </Button>
      <Modal
        title={<Typography>Confirm Refund?</Typography>}
        visible={showRefundModal}
        onCancel={() => setShowRefundModal(false)}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            form="refund-form"
            loading={refundLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <Space direction="vertical" size="small">
          <Typography>Refunding Order to: </Typography>
          <Typography>
            <strong>Name: </strong>
            {name}
          </Typography>
          <Typography>
            <strong>Email: </strong>
            {email}
          </Typography>
          <Typography>
            <strong>Phone: </strong>
            {phone}
          </Typography>
          <Form form={form} id="refund-form" onFinish={handleRefund}>
            <Form.Item label="Refund Type" name="refundType">
              <Select>
                {!useManual && <Option value="automatic">Automatic</Option>}
                <Option value="manual" disabled>
                  Manual
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Refund Notes"
              name="refundNotes"
              initialValue="Refund for order"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="Refund Amount"
              name="refundAmount"
              initialValue={amountToRefund}
            >
              <InputNumber
                min={0}
                max={amountToRefund}
                defaultValue={amountToRefund}
                disabled={!isPartialRefundAmountEnabled}
              />
            </Form.Item>
            <Form.Item
              label="Enable Partial Refund"
              name="partialRefundAmountEnabled"
              initialValue={isPartialRefundAmountEnabled}
            >
              <Checkbox
                defaultChecked={false}
                value={isPartialRefundAmountEnabled}
                onChange={(e) => {
                  setIsPartialRefundAmountEnabled(e.target.checked);
                  form.setFieldsValue({
                    partialRefundAmountEnabled: e.target.checked,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) =>
                prev?.refundType !== curr?.refundType
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("refundType") === "manual" && (
                  <>
                    <Form.Item
                      label="Manual Transfer Id"
                      name="manualRefundTransactionId"
                      rules={[
                        {
                          required: getFieldValue("refundType") === "manual",
                          message: "Please input manual refund transaction id",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Transfer Proof"
                      name="manualRefundPhotoProof"
                      rules={[
                        {
                          required: getFieldValue("refundType") === "manual",
                          message: "Please upload manual refund photo proof",
                        },
                      ]}
                    >
                      <Upload
                        accept={[".png", ".jpg", ".jpeg"]}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  );
};

RefundHandler.propTypes = {
  orderId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  refundState: PropTypes.string.isRequired,
  useManual: PropTypes.bool.isRequired,
  refundCurrency: PropTypes.string.isRequired,
  amountToRefund: PropTypes.number,
  isPartialRefunded: PropTypes.bool,
  isGSTEnabled: PropTypes.bool,
};

export default RefundHandler;
